import domHelpers from "./dom-helpers";
const { toggleClass } = domHelpers;

class ClassBurger {
  constructor(
    label,
    onClass = "open",
    callback = function() {
      return true;
    }
  ) {
    var dataToggles = document.querySelectorAll(
      "[data-toggle-el=" + label + "]"
    );
    if (dataToggles.length > 0) {
      // Bind toggle trigger
      this.bindTrigger(dataToggles, label, onClass, callback);
    } else {
      // eslint-disable-next-line
      console.warn(
        "Togglable element with value " + label + " does not exist."
      );
    }
  }

  bindTrigger(nodes, label, onClass, callback) {
    var toggleTriggers = document.querySelectorAll(
      "[data-toggle-trigger=" + label + "]"
    );
    if (toggleTriggers.length > 0) {
      // Bind click handler
      for (var i = 0; i < toggleTriggers.length; i++) {
        toggleTriggers[i].addEventListener("click", function() {
          for (var j = 0; j < nodes.length; j++) {
            toggleClass(nodes[j], onClass);
            callback(nodes[j]);
          }
        });
      }
    } else {
      // eslint-disable-next-line
      console.warn(
        "Trigger matching togglable element value" + label + "does not exist"
      );
    }
  }
}

export default ClassBurger;
