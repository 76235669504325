import domHelpers from "./dom-helpers";
import heightHelpers from "./height-helpers";

const { addClass, removeClass, hasClass } = domHelpers;
const { expandHeight: expand, collapseHeight: collapse } = heightHelpers;

// Height reveal an element by clicking a toggle trigger
class HeightReveal {
  constructor(
    dataToggle = "[data-height-toggle]",
    dataReveal = "[data-height-reveal]",
    onClass = "open"
  ) {
    // Get attribute names for each selector
    var toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    var revealAttr = dataReveal.match(/\[(.*)\]/)[1];

    // Find all elements with toggle data attribute
    var toggles = document.querySelectorAll(dataToggle);

    // Ensure those marked open on load are opened now
    Array.prototype.forEach.call(
      document.querySelectorAll(dataReveal + "." + onClass),
      el => {
        expand(el);
      }
    );

    for (var i = 0; i < toggles.length; i++) {
      // Get all of the reveal elements that match each trigger and create a class for it
      var toggleId = toggles[i].getAttribute(toggleAttr);
      var reveals = document.querySelectorAll(
        "[" + revealAttr + "=" + toggleId + "]"
      );

      // Create a class with the height trigger, a node list of its reveals, and the reveal and group attributes
      const heightTrigger = new HeightTrigger(
        toggles[i],
        reveals,
        onClass,
        revealAttr
      );
    }
  }
}

class HeightTrigger {
  constructor(triggerNode, revealNodes, onClass) {
    this.trigger = triggerNode;
    this.reveals = revealNodes;

    // Bind click handler
    this.trigger.addEventListener("click", event => {
      event.preventDefault();

      for (var i = 0; i < this.reveals.length; i++) {
        var revealNode = this.reveals[i];
        // Element needs to be opened;
        if (!hasClass(revealNode, onClass)) {
          expand(revealNode);

          addClass(this.trigger, onClass);
          addClass(revealNode, onClass);
        } else {
          collapse(revealNode);
          removeClass(this.trigger, onClass);
          removeClass(revealNode, onClass);
        }
      }
    });
  }
}

export default HeightReveal;
