import domHelpers from "./dom-helpers";
import heightHelpers from "./height-helpers";

const { addClass, removeClass, hasClass } = domHelpers;
const { expandHeight: expand, collapseHeight: collapse } = heightHelpers;

// Tabs are a special case of height-reveal toggles+reveals;
// They behave the same as height-reveal elements but utilize the "dataGroup" data attribute
// to handle closing all other tabs when opening a new tab (i.e. making sure only one tab can be open at a time)
// and clicking on an open tab does not close it
class TabGroup {
  constructor(
    dataToggle = "[data-tab-toggle]",
    dataReveal = "[data-tab]",
    onClass = "open",
    dataGroup = "[data-tab-group]"
  ) {
    // Get attribute names for each selector
    const toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    const revealAttr = dataReveal.match(/\[(.*)\]/)[1];

    // group attribute which the nodes share
    const groupAttr = dataGroup.match(/\[(.*)\]/)[1];

    // Find all elements with toggle data attribute
    const toggles = document.querySelectorAll(dataToggle);
    const slug = window.location.hash.substr(1);

    [...toggles].forEach((toggle, i) => {
      // Get all of the reveal elements that match each trigger and create a class for it
      const toggleId = toggle.getAttribute(toggleAttr);
      const reveals = document.querySelectorAll(
        "[" + revealAttr + "=" + toggleId + "]"
      );

      // Create a class with the height trigger, a node list of its reveals, and the reveal and group attributes
      const tabTrigger = new TabTrigger(
        toggle,
        reveals,
        onClass,
        revealAttr,
        groupAttr
      );

      // Initialize the first item, in case there are no active ones
      if (i === 0) {
        tabTrigger.updateReveals();
      }

      if (slug === toggleId) {
        tabTrigger.activate();
      }
    });
  }
}

class TabTrigger {
  constructor(triggerNode, revealNodes, onClass, revealAttr, groupAttr) {
    this.triggerNode = triggerNode;
    this.reveals = revealNodes;
    this.onClass = onClass;

    // Group to which the nodes belong
    this.revealSelector = revealAttr;

    // Find all members of the group
    this.groupTabs = document.querySelectorAll(
      "[" + groupAttr + "=" + this.triggerNode.getAttribute(groupAttr) + "]"
    );

    // this.initVisibility();

    // Bind click handler
    this.triggerNode.addEventListener("click", e => {
      e.preventDefault();
      this.activate();
    });
  }

  // initVisibility() {
  //   // [...this.reveals].forEach((revealNode) => {
  //   //   if (hasClass(revealNode, this.onClass)) {
  //   //     revealNode.style.maxHeight = 'none';
  //   //   }
  //   // });
  // }

  activate() {
    // Check if the tab that is clicked is already open (if so do nothing)
    if (hasClass(this.triggerNode, this.onClass)) {
      return;
    }

    // Loop through group members
    [...this.groupTabs].forEach(tab => {
      if (this.isActiveTab(tab)) {
        this.updateClickedTab(tab);
        this.updateUrl(tab);
      } else {
        this.updateNonClickedTab(tab);
      }
    });

    this.updateReveals();
  }

  isActiveTab(tab) {
    return tab === this.triggerNode;
  }

  updateClickedTab(tab) {
    // Toggle onClass on group member if it's the reveals' trigger
    if (!hasClass(tab, this.onClass)) {
      addClass(tab, this.onClass);
    } else {
      removeClass(tab, this.onClass);
    }
  }

  updateUrl(tab) {
    window.location.hash = tab.getAttribute("data-tab-toggle");
  }

  updateNonClickedTab(tab) {
    // If it's not the trigger remove onClass
    removeClass(tab, this.onClass);
    // If it's also a 'reveal' node collapse it
    if (tab.getAttribute(this.revealSelector)) {
      collapse(tab);
    }
  }

  updateReveals() {
    // Expand/collapse this trigger's reveals and add the onClass
    [...this.reveals].forEach(revealNode => {
      // Element needs to be opened;
      if (!hasClass(this.triggerNode, this.onClass)) {
        // Already handled class removal above
        collapse(revealNode);
      } else {
        addClass(revealNode, this.onClass);
        expand(revealNode);
        setTimeout(function() {
          revealNode.style.maxHeight = "none";
        }, 1000);
      }
    });
  }
}

export default TabGroup;
