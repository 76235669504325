import domHelpers from "./dom-helpers";

const { addClass, removeClass } = domHelpers;

class HeroSlider {
  constructor() {
    if (
      document.querySelector("[data-hero-figures]") &&
      document.querySelector("[data-hero-leads]")
    ) {
      this.initSlider();
    }
  }

  initSlider() {
    // Get initial nodes and statistics from markup
    // NB: Active page is a class variable
    // Set active page to 0 in case there is only one figure
    this.activePage = 0;
    this.activeClass = "active";

    var figureNodes = document
      .querySelector("[data-hero-figures]")
      .getElementsByTagName("figure");
    var leadsNodes = document.querySelector("[data-hero-leads]").childNodes;

    // Set a random starting slide if there is more than one slide
    if (figureNodes.length > 0) {
      this.activePage = Math.floor(Math.random() * figureNodes.length);
    }

    var pageNext = document.querySelector("[data-hero-next]");
    var pagePrev = document.querySelector("[data-hero-prev]");

    var ordinalTotal = document.querySelector("[data-hero-ordinal-total]");
    this.ordinalCurrent = document.querySelector("[data-hero-ordinal-current]");

    var totalPages = figureNodes.length;

    // Convert figures to class array for easier iteration later
    this.figures = [];
    var i = 0;
    for (i = 0; i < figureNodes.length; i++) {
      this.figures.push(figureNodes[i]);
    }

    // Assign lead nodes to a class array as well
    this.leads = [];
    for (i = 0; i < leadsNodes.length; i++) {
      if (
        leadsNodes[i].getAttribute &&
        leadsNodes[i].getAttribute("data-hero-lead") !== null
      ) {
        this.leads.push(leadsNodes[i].getElementsByClassName("hero-lead")[0]);
      }
    }

    // If, for some reason, the number of leads and heroes don't match...
    if (totalPages !== this.leads.length) {
      // eslint-disable-next-line
      console.warn(
        "Hero slider error: Number of leads does not match number of images"
      );
    }

    // Set up the first figure and lead with the active class
    this.swapActive(this.activePage, this.activeClass);

    // In any case, display the total in the dom
    ordinalTotal.innerHTML = this.leads.length;

    // And set the current to 1 + the active page
    this.ordinalCurrent.innerHTML = this.activePage + 1;

    // Bind click handlers for pagination
    pageNext.addEventListener("click", () => {
      this.stopRotate();
      this.next();
    });

    pagePrev.addEventListener("click", () => {
      this.stopRotate();
      this.previous();
    });

    this.startRotate();
  }

  startRotate(timing = 10000) {
    this.rotator = window.setInterval(() => {
      this.next();
    }, timing);
  }

  stopRotate() {
    clearInterval(this.rotator);
  }

  next() {
    if (this.activePage === this.leads.length - 1) {
      this.activePage = 0;
    } else {
      this.activePage += 1;
    }
    this.swapActive(this.activePage, this.activeClass);
  }

  previous() {
    if (this.activePage === 0) {
      this.activePage = this.leads.length - 1;
    } else {
      this.activePage -= 1;
    }
    this.swapActive(this.activePage, this.activeClass);
  }

  swapActive(ordinal, activeClass) {
    this.figures.forEach(figure => {
      removeClass(figure, activeClass);
    });
    this.leads.forEach(lead => {
      removeClass(lead, activeClass);
    });

    addClass(this.figures[ordinal], activeClass);
    addClass(this.leads[ordinal], activeClass);
    this.ordinalCurrent.innerHTML = ordinal + 1;
  }
}

export default HeroSlider;
