import domHelpers from "./dom-helpers";

const { addClass, removeClass } = domHelpers;

class NestedCheckboxes {
  constructor(
    categoryClass = "checkbox-category",
    checkboxClass = "checkbox",
    indicatorClass = "toggle-indicator"
  ) {
    // Find all elements with checkbox category class
    var categories = document.querySelectorAll("." + categoryClass);

    for (var i = 0; i < categories.length; i++) {
      var parentCheckbox = categories[i].querySelector("." + checkboxClass);
      var childCheckboxes = categories[i].nextElementSibling.children;

      if (childCheckboxes.length > 0) {
        const checkboxTriggers = new ParentCheckboxTriggers(
          parentCheckbox,
          childCheckboxes,
          indicatorClass
        );
      }
    }
  }
}

class ParentCheckboxTriggers {
  constructor(parent, children, indicatorClass) {
    for (var i = 0; i < children.length; i++) {
      // Bind click handler to nested checkboxes
      children[i].addEventListener("change", event => {
        this.partialityChecker(parent, children, indicatorClass, "child");
      });
    }

    // Bind click handler to parent checkbox
    parent.addEventListener("change", event => {
      this.partialityChecker(parent, children, indicatorClass, "parent");
    });
  }

  siblingsStatus(siblings) {
    var numChecked = 0;

    for (var i = 0; i < siblings.length; i++) {
      if (siblings[i].querySelector("input").checked) {
        numChecked++;
      }
    }
    return numChecked;
  }

  checkAll(checkboxes, indicatorClass) {
    for (var i = 0; i < checkboxes.length; i++) {
      var checkIndicator = checkboxes[i].querySelector("." + indicatorClass);
      checkboxes[i].querySelector("input").checked = true;
    }
  }

  uncheckAll(checkboxes, indicatorClass) {
    for (var i = 0; i < checkboxes.length; i++) {
      var checkIndicator = checkboxes[i].querySelector("." + indicatorClass);
      checkboxes[i].querySelector("input").checked = false;
    }
  }

  partialityChecker(parentBox, childrenBoxes, indicatorClass, trigger) {
    var parentIndicator = parentBox.querySelector("." + indicatorClass);

    switch (trigger) {
      case "child":
        var siblingStatus = this.siblingsStatus(childrenBoxes);
        var parentBoxInput = parentBox.querySelector("input");
        // between 1 and all children checkboxes checked
        if (siblingStatus > 0 && siblingStatus < childrenBoxes.length) {
          parentBoxInput.checked = false;
          addClass(parentIndicator, "partially-checked");
          // all children checkboxes checked
        } else if (siblingStatus === childrenBoxes.length) {
          parentBoxInput.checked = true;
          removeClass(parentIndicator, "partially-checked");
          // no children checkboxes checked
        } else {
          removeClass(parentIndicator, "partially-checked");
        }
        break;
      case "parent":
        removeClass(parentIndicator, "partially-checked");
        // parent checkbox checked
        if (parentBox.querySelector("input").checked) {
          this.checkAll(childrenBoxes, indicatorClass);
          // parent checkbox unchecked
        } else {
          this.uncheckAll(childrenBoxes, indicatorClass);
        }
        break;
    }
  }
}

export default NestedCheckboxes;
