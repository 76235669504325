import throttle from "lodash/throttle";

// Match Heights by data-attribute groups on resize!
class MatchHeights {
  constructor(dataSelect = "[data-mh]", unit = "px", minimumHeight = "0") {
    // Find all elements with data-attribute groups
    var matchHeightEls = document.querySelectorAll(dataSelect);

    // Create an object to keep each group in named arrays
    var dataAttr = dataSelect.match(/\[(.*)\]/)[1];
    var matchHeightGroups = {};

    // Iterate through each element with a data-attribute group
    for (var i = 0; i < matchHeightEls.length; i++) {
      var mhGroupId = matchHeightEls[i].getAttribute(dataAttr);
      // If there isn't a group for this groupID already, make one!
      if (!Object.prototype.hasOwnProperty.call(matchHeightGroups, mhGroupId)) {
        matchHeightGroups[mhGroupId] = [];
      }
      // Always push element to the group it belongs in
      matchHeightGroups[mhGroupId].push(matchHeightEls[i]);
    }

    // For each group...
    for (var id in matchHeightGroups) {
      if (!Object.prototype.hasOwnProperty.call(matchHeightGroups, id)) {
        // Run the height matching script on load
        var group = matchHeightGroups[id];
        this.matchHeights(group, unit, minimumHeight);

        // Then bind for it to run on resize (throttled)
        window.addEventListener(
          "resize",
          this.throttledMatch(group, unit, minimumHeight)
        );
      }
    }
  }

  matchHeights(group, unit, minimumHeight) {
    // Remove min-heights on all elements
    group.forEach(function(el) {
      el.style.minHeight = "auto";
    });

    // Get maximum height value
    var maxHeight = group
      .map(function(el) {
        return el.offsetHeight;
      })
      .reduce(function(a, b) {
        return Math.max(a, b);
      });

    // default unit is px, this checks for the only other we're concerned with right now: percentage
    if (unit === "%") {
      // converts maxHeight to a percentage of parent el
      maxHeight = (maxHeight / group[0].parentNode.offsetHeight) * 100;
    }

    // if the maxHeight is less than the minimumHeight use the minimumHeight
    if (maxHeight < minimumHeight) {
      maxHeight = minimumHeight;
    }

    // Set min-height value with css
    group.forEach(function(el) {
      el.style.minHeight = maxHeight + unit;
    });
  }

  throttledMatch(group, unit, minimumHeight) {
    return throttle(() => {
      this.matchHeights(group, unit, minimumHeight);
    }, 300);
  }
}

export default MatchHeights;
