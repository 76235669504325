import smoothScroll from "./smooth-scroll";

class FilteredListing {
  constructor(opts = {}) {
    this.el =
      typeof opts.el === "string" ? document.querySelector(opts.el) : opts.el;
    this.updates = opts.updates || {};
    this.afterUpdate = opts.afterUpdate;

    if (this.el) {
      this.initEvents();
    }
  }

  update(event) {
    const form = this.el;
    const obj = this;

    // Have to use jQuery for this October magic sauce
    window.jQuery(form).request("onFilter", {
      update: obj.updates,

      success: function(data) {
        // Call the parent success
        this.success(data);

        typeof obj.afterUpdate === "function" && obj.afterUpdate();

        // Reinitialize event listeners on pagination links
        obj.initInternalEvents();
      }
    });
  }

  initEvents() {
    const obj = this;
    const form = this.el;

    // Add the onchange submit handling
    this.el.addEventListener("change", function(event) {
      const pageInput = form.querySelector("[name=page]");
      if (pageInput) {
        pageInput.setAttribute("value", 1);
      }
      obj.update();
    });

    // These need to get initializated after each refresh, because they apply to some component(s) internal to
    //  the rendered result set.
    // eslint-disable-next-line
    this.initInternalEvents.call(this);
  }

  initInternalEvents() {
    const obj = this;
    const form = this.el;

    // Add the pagination click submit handling
    const paginationLinks = form.querySelectorAll("[data-list-page]");
    if (paginationLinks.length > 0) {
      Array.prototype.forEach.call(paginationLinks, el => {
        el.addEventListener("click", function(event) {
          event.preventDefault();
          const page = event.currentTarget.getAttribute("data-list-page");
          const pageInput = form.querySelector("[name=page]");
          if (pageInput) {
            pageInput.setAttribute("value", page);
          }
          obj.update();
          smoothScroll(form, 102);
        });
      });
    }

    const sortingLinks = form.querySelectorAll("[data-order-by]");
    if (sortingLinks.length > 0) {
      [...sortingLinks].forEach(el => {
        el.addEventListener("click", e => {
          e.preventDefault();
          const newOrderBy = el.getAttribute("data-order-by");
          if (this.getOrderBy() === newOrderBy) {
            this.toggleOrderByDirection();
          } else {
            this.resetOrderByDirection();
          }
          this.setOrderBy(newOrderBy);
          obj.update();
        });
      });
    }
  }

  getOrderByDirection() {
    return this.el.querySelector('[name="order_by_direction"]').value;
  }

  resetOrderByDirection() {
    this.el
      .querySelector('[name="order_by_direction"]')
      .setAttribute("value", "");
  }

  toggleOrderByDirection() {
    const newDirection = this.getOrderByDirection() === "desc" ? "asc" : "desc";
    this.el
      .querySelector('[name="order_by_direction"]')
      .setAttribute("value", newDirection);
  }

  getOrderBy() {
    return this.el.querySelector('[name="order_by"]').value;
  }

  setOrderBy(orderBy) {
    this.el.querySelector('[name="order_by"]').setAttribute("value", orderBy);
  }
}

export default FilteredListing;
