// Import CIC classes, functions
import domHelp from "./dom-helpers.js";
import MatchHeights from "./match-heights.js";
import Links from "./links.js";
import ClassBurger from "./class-burger.js";
import HeightReveal from "./height-reveal.js";
import TabGroup from "./tabs.js";
import Togglers from "./togglers.js";
import Overlays from "./overlays.js";
import AccordianGroup from "./accordians.js";
import FilteredList from "./filtered-list.js";
import HeroSlider from "./hero-slider.js";
import NestedCheckboxes from "./nested-checkboxes.js";
import SloanSearch from "./search.js";
import InputSelect from "./input-select.js";
import smoothScroll from "./smooth-scroll.js";

class SloanTheme {
  init() {
    // Import External Libraries
    var Headroom = require("./lib/headroom.min.js");

    // When DOM is ready///
    domHelp.ready(function() {
      // Initialize height matching on elements grouped by 'data-mh' attributes
      var matchHeightGroups = new MatchHeights("[data-mh]");

      // Initialize height matching on the image grid fig captions passing a minimum height and unit to work with image-grid markup
      var matchHeightGroupsGrid = new MatchHeights("[data-mh-grid]", "%", 36);

      // Initialize Overlay Burgers
      var hamburgerOverlay = new ClassBurger("hamburger", "open", function(
        node
      ) {
        domHelp.addEscListener(node, "open");
        var body = document.querySelector("body");

        if (domHelp.hasClass(node, "open")) {
          domHelp.addClass(body, "overlay-open");
        } else {
          domHelp.removeClass(body, "overlay-open");
        }
      });

      // Initialize Height Reveal Triggers/Elements
      var heightRevealTriggers = new HeightReveal();

      // Initialize Filters
      var selectFilters = new Togglers(
        "[data-filter-toggle]",
        "[data-filter-select]",
        "open"
      );

      // Initialize Filters sidebar toggle
      var sidebars = new Togglers(
        "[data-sidebar-toggle]",
        "[data-sidebar]",
        "open"
      );

      // Initialize Media Modals
      var mediaModals = new Overlays(
        "[data-media-modal-toggle]",
        "[data-media-modal]",
        "open"
      );

      // Initialize Tabs (special Height Reveal Triggers/Elements)
      var Tabs = new TabGroup();

      // Initialize Accodians (special Tabs)
      var Accordians = new AccordianGroup();

      // Search overlay adds focus when it is open
      var searchOverlay = new ClassBurger("site-search", "open", function(
        node
      ) {
        var searchInput = node.getElementsByTagName("input")[0];
        searchInput.focus();
        domHelp.addEscListener(node, "open");

        var body = document.querySelector("body");

        if (domHelp.hasClass(node, "open")) {
          domHelp.addClass(body, "overlay-open");
        } else {
          domHelp.removeClass(body, "overlay-open");
        }
      });

      // Initialized fade-in fixed header
      var fixedHeader = document.querySelector("[data-header-fixed]");
      var fixedHeadroom = new Headroom(fixedHeader, {
        offset: 300
      });
      fixedHeadroom.init();

      // Initialize smooth Scrolling anchor links and external links
      var allLinks = new Links();

      // Scroll to link if there's a # in the url
      var urlHash = document.location.hash;
      if (urlHash.length) {
        // Find match
        var hashChunks = urlHash.split("#");
        var match = document.querySelector(
          'a[name="' + hashChunks[hashChunks.length - 1] + '"]'
        );
        var headerHeight = document.querySelector(".header-fixed").scrollHeight;
        // Bind smooth scrolling and reset the hash when it gets there
        setTimeout(function() {
          // Bind smooth scrolling and reset the hash when it gets there
          smoothScroll(match, headerHeight, function() {
            if (history.pushState) {
              history.pushState(
                null,
                null,
                "#" + hashChunks[hashChunks.length - 1]
              );
            } else {
              window.location.hash = hashChunks[hashChunks.length - 1];
            }
          });
        }, 100);
      }

      const grantsList = new FilteredList({
        el: "[data-filtered-grants]",
        updates: {
          "grantlist::grants/list": "[data-grants-results]",
          "grantlist::pagination": "[data-grants-pagination]",
          "grantlist::grants/count": "[data-grants-result-count]"
        },
        afterUpdate: () => {
          // Reinitialize the accordions inside the results
          const accordionGroup = new AccordianGroup();
        }
      });

      const fellowsList = new FilteredList({
        el: "[data-filtered-fellows]",
        updates: {
          "grantlist::fellows/list": "[data-grants-results]",
          "grantlist::pagination": "[data-grants-pagination]",
          "grantlist::fellows/count": "[data-grants-result-count]"
        }
      });

      /**
       * Awards listings
       */
      [...document.querySelectorAll("[data-filtered-awards]")].forEach(el => {
        const key = el.getAttribute("data-filtered-awards");
        const filteredList = new FilteredList({
          el: el,
          updates: {
            "awards::list": '[data-grants-results="' + key + '"]',
            "awards::count": '[data-grants-result-count="' + key + '"]'
          }
        });
      });

      const mediaList = new FilteredList({
        el: "[data-filtered-media]",
        updates: {
          "medialist::results": "[data-media-results]",
          "medialist::pagination": "[data-media-pagination]",
          "medialist::count": "[data-media-result-count]"
        },
        afterUpdate: () => {
          // Reinitialize modal toggles
          const overlays = new Overlays(
            "[data-media-modal-toggle]",
            "[data-media-modal]",
            "open"
          );
        }
      });

      const newsList = new FilteredList({
        el: "[data-filtered-news]",
        updates: {
          "newslist::results": "[data-news-results]",
          "newslist::pagination": "[data-news-pagination]",
          "newslist::filters": "[data-news-filters]"
        }
      });

      const eventList = new FilteredList({
        el: "[data-filtered-events]",
        updates: {
          "eventlist::results": "[data-events-results]",
          "eventlist::pagination": "[data-events-pagination]",
          "eventlist::filters": "[data-events-filters]",
          "eventlist::count": "[data-events-result-count]"
        },
        afterUpdate: () => {
          // Reinitialize the accordions inside the results
          const accordionGroup = new AccordianGroup();
        }
      });

      var nestedCheckboxes = new NestedCheckboxes();

      const search = new SloanSearch({
        el: "[data-sloan-search]",
        filters: "[data-search-filter]"
      });
    });

    // Initialize Hero Sliders
    var heroSlider = new HeroSlider();

    const inputSelect = new InputSelect("[data-autoselect-all]");
  }
}

export default SloanTheme;
