var domHelpers = {
  hasClass: function(el, className) {
    if (el.classList) return el.classList.contains(className);
    else
      return !!el.className.match(
        new RegExp(`(\\s|^)${className}(\\s|$)`)
      );
  },

  addClass: function(el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else if (!this.hasClass(el, className)) {
      el.className += " " + className;
    }
  },

  removeClass: function(el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else if (this.hasClass(el, className)) {
      var reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
      el.className = el.className.replace(reg, " ");
    }
  },

  toggleClass: function(el, className) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      var classes = el.className.split(" ");
      var existingIndex = classes.indexOf(className);

      if (existingIndex >= 0) classes.splice(existingIndex, 1);
      else classes.push(className);

      el.className = classes.join(" ");
    }
  },

  ready: function(f) {
    if (document.readyState !== "loading") {
      f();
    } else {
      document.addEventListener("DOMContentLoaded", f);
    }
  },

  getScrollTop: function() {
    return document.body.scrollTop || document.documentElement.scrollTop;
  },

  setScrollTop: function(value) {
    document.documentElement.scrollTop = value;
    document.body.scrollTop = value;
  },

  getUserAgent: function(value) {
    // Get User Agent with simple, filtered values for some browsers
    // From http://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      userAgent = "windows-phone";
    }

    if (/android/i.test(userAgent)) {
      userAgent = "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      userAgent = "ios";
    }

    return userAgent;
  },

  addEscListener: function(node, className) {
    document.addEventListener("keyup", function onEscHandler(event) {
      // If escape key is pressed close the overlay and remove the event listener
      if (event.keyCode === 27) {
        if (node.classList) {
          node.classList.remove(className);
        } else if (this.hasClass(node, className)) {
          var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
          node.className = node.className.replace(reg, " ");
        }

        if (document.body.classList) {
          document.body.classList.remove("overlay-open");
        } else if (this.hasClass(document.body, "overlay-open")) {
          var regOverlay = new RegExp("(\\s|^)overlay-open(\\s|$)");
          document.body.className = document.body.className.replace(
            regOverlay,
            " "
          );
        }
        document.removeEventListener("keyup", onEscHandler);
      }
    });
  }
};

export default domHelpers;
