import domHelpers from './dom-helpers';

const { toggleClass } = domHelpers;

// For toggling class on trigger and its set of elements
class Togglers {
  constructor(dataToggle = '[data-toggler]', dataTarget = '[data-toggler]', onClass = 'open') {
    var togglers = document.querySelectorAll(dataToggle);
    var toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    var targetAttr = dataTarget.match(/\[(.*)\]/)[1];

    for (var i = 0; i < togglers.length; i ++) {
      togglers[i].addEventListener('click', function(event) {
        event.preventDefault();
        var toggleId = this.getAttribute(toggleAttr);
        var targets = document.querySelectorAll('[' + targetAttr + '="' + toggleId + '"]');
        toggleClass(this, onClass);

        for (var index = 0; index < targets.length; index ++) {
          toggleClass(targets[index], onClass);
        }
      });
    }
  }
}

export default Togglers;
