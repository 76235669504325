class SloanSearch {
  constructor(opts = {}) {
    this.el = document.querySelector(opts.el);
    this.opts = Object.assign({}, opts);

    if(this.el) {
      this.initEvents();
    }
  }

  initEvents() {
    this.el.addEventListener('submit', () => { this.submit(); return false; });
    if(this.opts.filters) {
      const filters = this.el.querySelectorAll(this.opts.filters);
      for (const filter of filters) {
        filter.addEventListener('change', (e) => {
          this.submit();
        });
      }
    }
  }

  setQuery(v) {
    this.el.querySelector('input[name="q"]').setAttribute('value', v);
  }

  enteredValue(selector) {
    return this.el.querySelector(selector).value;
  }

  submit() {
    const q = this.enteredValue('[data-proxy-q]');
    if(q) {
      this.setQuery(q);
    }
    this.el.submit();
  }
}

export default SloanSearch;
