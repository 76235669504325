import domHelp from './dom-helpers.js';

class Overlays {
  constructor(dataToggle = '[data-toggler]', dataTarget = '[data-toggler]', onClass = 'open') {
    var togglers = document.querySelectorAll(dataToggle);
    var toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    var targetAttr = dataTarget.match(/\[(.*)\]/)[1];

    if (togglers.length > 0) {
      // Bind toggle trigger
      this.bindTrigger(togglers, onClass, toggleAttr, targetAttr);
    }
  }

  bindTrigger(togglers, onClass, toggleAttr, targetAttr) {
    for (var i = 0; i < togglers.length; i ++) {
      togglers[i].addEventListener('click', function(event) {
        event.preventDefault();
        var toggleId = this.getAttribute(toggleAttr);
        var targets = document.querySelectorAll('[' + targetAttr + '="' + toggleId + '"]');
        domHelp.toggleClass(this, onClass);

        for (var j = 0; j < targets.length; j ++) {
          domHelp.toggleClass(targets[j], onClass);
          domHelp.addEscListener(targets[j], onClass);
          var windowHeight = window.innerHeight;
          var inner = targets[j].querySelectorAll('.inner')[0];
          inner.style.top = ((windowHeight / 2) - (inner.offsetHeight / 2) - 80) + 'px';

          if (domHelp.hasClass(targets[j], onClass)) {
            domHelp.addClass(document.querySelector('body'), 'overlay-open');
          } else {
            domHelp.removeClass(document.querySelector('body'), 'overlay-open');
          }
        }
      });
    }
  }
}

export default Overlays;
