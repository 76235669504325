// Smooth Scrolling without jQuery
// Based on: https://coderwall.com/p/hujlhg/smooth-scrolling-without-jquery
import domHelpers from "./dom-helpers";
const { getScrollTop, setScrollTop, getUserAgent } = domHelpers;

// based on http://en.wikipedia.org/wiki/Smoothstep
var smoothStep = function(start, end, point) {
  if (point <= start) {
    return 0;
  }
  if (point >= end) {
    return 1;
  }
  var x = (point - start) / (end - start);
  return x * x * (3 - 2 * x);
};

var smoothScroll = function(element, offset, callback) {
  offset = typeof offset !== "undefined" ? offset : 0;

  var targetRect = element.getBoundingClientRect();
  var target = targetRect.top + getScrollTop();

  // Set scroll speed based on where element is
  var duration = target / 2;

  if (duration === 0) {
    setScrollTop(target);
  }

  var startTime = Date.now();
  var endTime = startTime + duration;

  var startTop = getScrollTop();
  var distance = target - startTop - offset;
  // Running value that gets updated to compare frame scrolls
  var previousTop = startTop;

  // Looping frame scroll
  var scrollFrame = function() {
    // If scroll was interrupted, stop that stuff
    if (getScrollTop() !== previousTop) {
      return;
    }

    // Set scrolltop for this frame
    var now = Date.now();
    var point = smoothStep(startTime, endTime, now);
    setScrollTop(Math.round(startTop + distance * point));

    // Check if done
    if (now >= endTime) {
      // Run a callback if there is one
      if (callback) {
        callback();
      }
      return;
    }

    previousTop = getScrollTop();

    // Schedule next frame
    setTimeout(scrollFrame, 0);
  };

  // On Desktop...
  if (getUserAgent() !== "android" && getUserAgent() !== "ios") {
    // Start animation
    setTimeout(scrollFrame, 0);
  } else {
    // On mobile, just go to the point
    setScrollTop(startTop + distance);
  }
};

export default smoothScroll;
