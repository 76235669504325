import domHelpers from "./dom-helpers";
import heightHelpers from "./height-helpers";

const { addClass, removeClass, hasClass } = domHelpers;
const { expandHeight: expand, collapseHeight: collapse } = heightHelpers;

// Accordians are a special case of height-reveal toggles+reveals;
// They behave the same as height-reveal elements but utilize the "dataGroup" data attribute
// to handle closing the starting elemen when revealing the 'accoridaned' element
// and clicking on an open accordian's toggle collapses it )i.e. returns it to its start state
class AccordianGroup {
  constructor(
    dataToggle = "[data-accordian-toggle]",
    dataReveal = "[data-accordian]",
    onClass = "open",
    dataGroup = "[data-accordian-group]"
  ) {
    // Get attribute names for each selector
    var toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    var revealAttr = dataReveal.match(/\[(.*)\]/)[1];
    // group attribute which the nodes share
    var groupAttr = dataGroup.match(/\[(.*)\]/)[1];

    // Find all elements with toggle data attribute
    var toggles = document.querySelectorAll(dataToggle);

    for (var i = 0; i < toggles.length; i++) {
      // Get all of the reveal elements that match each trigger and create a class for it
      var toggleId = toggles[i].getAttribute(toggleAttr);
      var reveals = document.querySelectorAll(
        "[" + revealAttr + "=" + toggleId + "]"
      );

      // Create a class with the height trigger, a node list of its reveals, and the reveal and group attributes
      const accordianTrigger = new AccordianTrigger(
        toggles[i],
        reveals,
        onClass,
        revealAttr,
        groupAttr
      );
    }
  }
}

class AccordianTrigger {
  constructor(triggerNode, revealNodes, onClass, revealAttr, groupAttr) {
    this.trigger = triggerNode;
    this.reveals = revealNodes;

    // Group to which the nodes belong
    this.revealSelector = revealAttr;
    // Find all members of the group
    this.groupAccordians = document.querySelectorAll(
      "[" + groupAttr + "=" + this.trigger.getAttribute(groupAttr) + "]"
    );

    // Bind click handler
    this.trigger.addEventListener("click", event => {
      event.preventDefault();
      var i = 0;

      // Loop through group members
      for (i = 0; i < this.groupAccordians.length; i++) {
        if (this.groupAccordians[i] === this.trigger) {
          // Toggle onClass on group member if it's the reveals' trigger
          if (!hasClass(this.trigger, onClass)) {
            addClass(this.trigger, onClass);
          } else {
            removeClass(this.trigger, onClass);
          }
          // If it's not the trigger remove onClass
        } else {
          removeClass(this.groupAccordians[i], onClass);
          // If it's also a 'reveal' node collapse it
          if (this.groupAccordians[i].getAttribute(this.revealSelector)) {
            collapse(this.groupAccordians[i]);
          }
        }
      }

      // Loop through all of this trigger's reveals, expand/collapse them and add the onClass
      for (i = 0; i < this.reveals.length; i++) {
        var revealNode = this.reveals[i];
        // Element needs to be opened;
        if (!hasClass(this.trigger, onClass)) {
          // Already handled class removal above
          collapse(revealNode);
        } else {
          addClass(revealNode, onClass);
          expand(revealNode);
          setTimeout(function() {
            revealNode.style.maxHeight = "none";
          }, 1000);
        }
      }
    });
  }
}

export default AccordianGroup;
