import smoothScroll from "./smooth-scroll.js";

class Links {
  constructor() {
    // Find all links
    var links = document.links;
    // Track if onhashchange needs to be prevented
    this.anchorsPresent = false;

    for (var i = 0; i < links.length; i++) {
      const currentLink = links[i];
      const url = currentLink.getAttribute("href");
      const urlChunks = url.split("/");
      const linkPrefix = urlChunks[urlChunks.length - 1];
      if (linkPrefix.includes("#")) {
        var anchor = new AnchorLink(currentLink);
        if (anchor.getAnchorStatus) {
          this.anchorsPresent = true;
        }
        // If the link is an external link add attribute to force it to open in a new window/tab
      } else if (this.shouldBeExternal(url)) {
        const externalLink = new ExternalLink(currentLink);
      }
    }
    // Prevent default onhashchange, just on time
    if (this.anchorsPresent) {
      window.addEventListener("hashchange", function(event) {
        event.preventDefault();
      });
    }
  }

  shouldBeExternal(url) {
    return url.match(/(^((https?:)?\/\/))|(\.(pdf|docx?|pptx?|xlsx?)$)/i);
  }
}

class AnchorLink {
  constructor(node) {
    this.anchor = node.getAttribute("href").substring(1);
    const anchorTargetChunks = this.anchor.split("#");
    const anchorTarget = anchorTargetChunks[anchorTargetChunks.length - 1];
    this.anchorsPresent = false;
    // This is an anchor link. If it has a match on the page, bind it
    // to scroll smoothly
    var match = document.querySelector('a[name="' + anchorTarget + '"]');
    if (match) {
      this.anchorsPresent = true;
      node.addEventListener("click", function(event) {
        event.preventDefault();
        var headerHeight = document.querySelector(".header-fixed").scrollHeight;
        // Find match again and scroll to it
        var anchorChunks = this.getAttribute("href")
          .substring(1)
          .split("#");
        var anchor = anchorChunks[anchorChunks.length - 1];
        var match = document.querySelector('a[name="' + anchor + '"]');

        // Bind smooth scrolling and reset the hash when it gets there
        smoothScroll(match, headerHeight, function() {
          if (history.pushState) {
            history.pushState(null, null, "#" + anchor);
          } else {
            window.location.hash = anchor;
          }
        });
      });
      return true;
    }
  }

  getAnchorStatus() {
    return this.anchorsPresent;
  }
}

class ExternalLink {
  constructor(node) {
    node.setAttribute("target", "_blank");
  }
}

export default Links;
